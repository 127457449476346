@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;

  color: white;
}

.buttonMain {
  border: solid 1px #49de80;
  border-radius: 15px;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 1px;
  cursor: pointer;
  transition: all 0.3s;
}

.buttonMain:hover {
  background-color: #49de80;
  color: white;
}

.buttonSecondary {
  border: solid 1px white;
  border-radius: 15px;
  color: white;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.buttonSecondary:hover {
  border: solid 1px #2eb355;

  color: white;
}

.buttonDisabled {
  border: solid 1px gray;
  border-radius: 15px;
  color: gray;
  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 1px;
  cursor: pointer;
  transition: all 0.3s;
}

.buttonDisabled:hover {
  cursor: not-allowed;
}

.rotated {
  transform: rotate(180deg);
}
